@font-face {
  font-family: "greatvibes";
  src: url(./../../public/assets/font/GreatVibes-Regular.ttf) format("truetype");
}

.product-page {
  position: relative;
}

.product-page .banner-image {
  width: 100%;
}

.product-page .banner-image img {
  width: 100%;
}

.product-page .product-content {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: white;

}

.product-page .product-content p {
  font-weight: 700;
  font-size: 50px;
  line-height: 70px;
  letter-spacing: 0.5%;
  margin-bottom: 0;
}

.product-page .product-content span {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.5%;
}

.organic-products {
  margin-top: 50px;
  padding: 0 100px;
}

.organic-products h6 {
  font-family: greatvibes;
  font-weight: 400;
  font-size: 35px;
  line-height: 50px;
  letter-spacing: 0.5%;
  margin-bottom: 0;
}

.organic-products h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 46px;
  letter-spacing: 0.5%;
  margin-bottom: 0;
}

.organic-products .product-para {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.5%;
  margin-bottom: 0;
  width: 80%;
  margin: auto;
}

.organic-products .border-bottom {
  border-bottom: 4px solid #50AE31 !important;
  width: 150px;
  height: 3px;
  margin: auto;
}

.organic-products .product-card {
  min-height: 387px;
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
}

.organic-products .product-card .inner-cards {
  width: 21%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.focus_background {
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 10px;
}

.organic-products .product-card .inner-cards:hover {
  width: 22%;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 10px;
}

.organic-products .product-card .inner-cards .card_img {
  border-radius: 100%;
}

.organic-products .product-card .inner-cards .card_img img {
  width: 97%;
  height: 189px ;
  border-radius: 100%;
}

.organic-products .product-card .inner-cards .storage-content {
  width: 95%;
  margin: 20px auto;
}

.organic-products .product-card .inner-cards .storage-content p {
  text-align: center;
  color: #004684;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0;
}

.organic-products .border-bottom-card {
  border: 1px solid #000000;
}

.organic-products .product-list .col-lg-3 {
  margin-top: 36px;
}

.organic-products .product-list .product-list-card {
  background: #FAFAFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: relative;
  height: 406px;
}

.organic-products .product-list .product-list-card .product-image {
  padding-top: 20px;
  width: 75%;
  margin: auto;
  height: 61%;
}

.organic-products .product-list .product-list-card .product-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.organic-products .product-list .product-list-card .product-content {
  padding: 20px;
  height: 35%
}

.organic-products .product-list .product-list-card .product-content p {
  margin-bottom: 0;
}

.organic-products .product-list .product-list-card .product-content p.product_name {
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  color: #004684;
}

.organic-products .product-list .product-list-card .product-content p.product_tag_line {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #50AE31;
  max-width: 90%;
  width: 100%;
  overflow-wrap: anywhere;
  min-height: 40px;
}

.organic-products .product-list .product-list-card .product-content button {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #004684;
  border: 1px solid #004684;
  padding: 7px;
}

.organic-products .product-list .product-list-card .product-content .product_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product_variant {
  color: #004684;
  text-align: right;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.organic-products .product-list .product-list-card .product-image-b {
  border: 1px solid #004684;
  margin: 0 20px;
}

.organic-products .product-list-card::before {
  background: #004684;
  position: absolute;
  width: 16%;
  height: 16%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-bottom-left-radius: 43%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 43%;
  left: 5px;
  top: 0;
  font-size: 12px;
}
@media (max-width : 1498px){
  .organic-products .product-card .inner-cards .card_img img {
   
    height: 241px ;
}
}

@media (max-width : 1440px) {
  .organic-products .product-list .product-list-card .product-content p.product_name {
    font-size: 18px;
  }
  .organic-products .product-card .inner-cards .card_img img {
   
    width: 97%;
    height: 189px ;
}
}

@media(max-width: 1080px) {
  .product-page .product-content {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .products-container .product-price {
    min-height: auto !important;
  }

  .product-page .product-content p {
    font-weight: 700;
    font-size: 45px;
    line-height: 70px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
  }

  .product-page .product-content span {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.5%;
  }

  .organic-products {
    margin-top: 50px;
    padding: 0 80px;
  }

  .organic-products h6 {
    font-family: greatvibes;
    font-weight: 400;
    font-size: 35px;
    line-height: 50px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
  }

  .organic-products h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 46px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
  }

  .organic-products .product-para {
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
    width: 95%;
    margin: auto;
    
    text-align: justify;
  }

  .organic-products .border-bottom {
    border-bottom: 4px solid #50AE31 !important;
    width: 150px;
    height: 3px;
    margin: auto;
  }

  .organic-products .product-card {
    margin: 30px 0;
    display: flex;
    min-height: 291px;
    justify-content: space-between;
  }

  .organic-products .product-card .inner-cards {
    width: 19.7%;
  }

  .organic-products .product-card .inner-cards .card_img {
    border-radius: 100%;
  }

  .organic-products .product-card .inner-cards .card_img img {
    width: 100%;
    height: 170px;
    border-radius: 100%;
  }

  .organic-products .product-list .product-list-card .product-content {
    padding: 0 20px 20px;
  }

  .organic-products .product-list .product-list-card .product-content p {
    margin-bottom: 0;
  }

  .organic-products .product-list .product-list-card .product-content p.product_name {
    font-weight: 500;
    font-size: 18px;
    line-height: 31px;
    color: #004684;
    min-height: 62px;
  }

  .organic-products .product-list .product-list-card {
    height: 413px;
  }
}
@media (max-width: 1025px){
  .organic-products .product-card .inner-cards .card_img img {
    height: 152px;
}
}

@media(max-width:768px) {
  .organic-products .product-list .product-list-card .product-content p.product_name {
    font-size: 14px;
    min-height: 32px;
  }

  .organic-products .product-list .product-list-card .product-content {
    padding: 0 12px;
  }

  .product_variant {
    font-size: 13px;
  }

  .organic-products .product-card .inner-cards .storage-content p {
    font-size: 16px;
  }

  .organic-products .product-card {
    min-height: 293px;
  }
  
.organic-products .product-card .inner-cards .card_img img {
  height: 116px;
}
.organic-products .product-para {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.5%;
  margin-bottom: 0;
  width: 95%;
  margin: auto;
  text-align: justify;

}
}



@media (max-width: 525px) {
  .organic-products .product-list .product-list-card {
    height: 354px;
}
.organic-products .product-list .product-list-card .product-content button {
  font-size: 11px;
  padding: 2px;
}

  .product-page .product-content {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .product-page .product-content p {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
  }

  .product-page .product-content span {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.5%;
  }

  .product-page .banner-image {
    height: 250px;
  }

  .product-page .banner-image img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .organic-products {
    padding: 0 20px !important;
  }

  .organic-products h6 {
    font-family: greatvibes;
    font-weight: 400;
    font-size: 35px;
    line-height: 50px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
  }

  .organic-products h4 {
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
  }

  .organic-products .product-para {
    font-weight: 400;
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.5%;
    margin-bottom: 0;
    width: 95%;
    margin: auto;
    text-align: justify;
  }

  .organic-products .border-bottom {
    border-bottom: 4px solid #50AE31 !important;
    width: 150px;
    height: 3px;
    margin: auto;
  }

  .organic-products .product-card {
    margin: 30px 0;
    width: 100%;
    min-height: 169px
  }

  .organic-products .product-card .inner-cards .card_img {
    border-radius: 100%;
  }

 

  .organic-products .product-card .inner-cards .storage-content {
    margin: 10px auto 10px 0;
  }

  .organic-products .product-list .product-list-card .product-content {
    padding: 0 10px 10px;
  }

  .organic-products .product-list .product-list-card .product-content p {
    margin-bottom: 0;
  }

  .organic-products .product-list .product-list-card .product-content p.product_name {
    font-weight: 500;
    color: #004684;
    font-size: 12px;
    min-height: 44px;
    line-height: 20px;
    margin-top: 7px;
  }

  .related_product .related_product_heading h4 {
    font-size: 25px;
  }

  .organic-products .product-card .inner-cards .storage-content p {
    font-size: 11px;
    line-height: 20px;
  }

  .organic-products .product-card .inner-cards:hover {
    padding: 6px;
}

  .product-list .products-container {
    width: 50%;
  }

  .organic-products .product-list .product-list-card .product-content p.product_tag_line {
    font-size: 12px;
    max-width: 100%;
  }

  .organic-products .product-list-card::before {
    width: 21%;
    height: 13%;
    font-size: 10px;
  }

  .organic-products .product-list .product-list-card {

    border-radius: 6px;
  }

  .organic-products .product-list .col-lg-3 {
    margin-top: 26px;
  }

  .products-container .product-price {
    min-height: auto !important;
  }

  .organic-products .product-list .product-list-card .product-image {
   
    width: 81%;
   
    height: 57%;

}
.organic-products .product-card .inner-cards .card_img img {
  width: 100%;
  height: 73px;
  border-radius: 100%;
}

}

@media (max-width: 376px){
  .organic-products .product-card .inner-cards .card_img img {
    width: 98%;
    height: 60px;
    border-radius: 100%;
  }
}
@media (max-width: 321px){
  .organic-products .product-card .inner-cards .card_img img {
    width: 100%;
   
    height: 50px;
    border-radius: 100%;
  }
}


/* # sourceMappingURL=product.css.map */