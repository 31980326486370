.accordion-custome {
    background-color: #ccc;
    color: #444;
    cursor: pointer;
    /* padding: 18px; */
    padding: 4px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
}

.account-table td{
    vertical-align: middle;
}

.card-header .btn-primary{
    width: 85px;
}

.account-table thead td{
    font-size: 16px;
}

.active-custome,
.accordion-custome:hover {
    background-color: #ccc;
}

.panel td {
    width: 21%; 
}


/* .accordion-custome:after {
    content: '\02795';
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
    margin-top: -5%;
    margin-left: 90%;
    position: absolute;
} */

/* .active-custome:after {
    content: "\2796";
} */
/* .caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
} */

.searchLogistics{
    width: 20%;
}
