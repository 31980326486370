.add-btn {
    color: #fff;
}
.add-btn:hover {
    color: #fff;
} 
/* .error {
    color: red;
    font-size: 13PX;
} */
.required {
    color: red;
}
.invalidDataError {
    color: red !important;
}


.syncTallyButton{
margin: 20px 20px 0px 0px;
}

.overlayMessage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    padding-top: 75px;
    font-weight: 500;
    text-align: center;
  }