body {
    width: 100%;
    height: 100%;
    background-color: white;
}

/* AboutusBanner */
.about-container {
    height: 300px;
    background: url('/public/img/aboutuspage/image51.png') no-repeat;
    background-size: cover;

}

.about-banner-outer-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(356.37deg, rgba(17, 13, 19, 0.4) 2.68%, rgba(0, 0, 0, 0.4) 71.46%);
    height: 100%;
}

.about-banner-inner-container {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    position: relative;
    top: 100px;
    text-align: center;
}

.about-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: white;
    margin: 0;
}


.about-home-text {
    color: white;
}

.about-us-text {
    color: #50AE31;
}

/* Overview Section */
.overview-container {
    background: url('/public/img/bg-pattan1.png') no-repeat;
    background-size: cover;
    background-color: #c4eec4;
    padding: 3rem 5rem;
}

.overview-chemical-free-heading {
    font-family: 'greatvibes', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 74.52%;
    margin: 0;
    letter-spacing: 0.005em;
}

.overview-heading {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    margin-top: 8px;
    margin-bottom: 0;
}

.overview-detail {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 156.52%;
    text-align: center;
    letter-spacing: 0.005em;
    width: 90%;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 25px;
}

.content-container {
    text-align: center;
}

.data {
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    margin: 0;
    color: #004684;
}

.client-data {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin: 0;
}

/* Partner section */
.partners-container {
    padding: 2.5rem 5rem;
    height: auto;
}


.partners {

    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 156.52%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #004684;
}

.partners-detail {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 156.52%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #004684;
    width: 45%;
    margin: auto;
}

.aboutus-image-container {
    margin-top: 40px;
}

/* About Leadership section */
.about-leadership-container {
    padding: 2rem 5rem;
    font-style: normal;
    color: #004684;

}

.about-leadership-detail {
    width: 50%;
}

.about-founder-img {
    width: 50%;
    display: flex;
    justify-content: center;
}

.leadership-underline {
    width: 127px;
    height: 4px;
    background: #50ae31;
}

.leadership-heading {
    font-family: 'greatvibes', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    letter-spacing: 0.005em;
    color: #004684;
}

.about-leadership-heading {
    margin: 0;
}

.leadership-underline {
    display: block;
}

.leadership-sub-heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
}

.leadership-detail {
    font-weight: 500;
    font-size: 15px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
}

.about-founder-img img {
    border-radius: 20px;
}


/* NM-Team section */
.nm-team-container {
    padding: 2rem 5rem;
}

.team-img-container {
    margin: 30px 0;
    width: 100%;
}

.nm-team-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: #004684;
    margin: 0;
}

.team-heading-underline {
    width: 227px;
}

.team-detail {
    position: relative;
    top: 0;
    left: 0;
    display: inline;
    border-radius: 7px;
    width: 30%;
    content: '';
    z-index: 1;
}

.team-detail img {
    width: 100%;
    height: 100%;
}

.team-member-detail {
    position: absolute;
    bottom: 13px;
    padding: 0 26px;
    color: #FFFFFF;
}

.outer-img-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    content: '';
    z-index: 1;
    transition: opacity 0.2s ease-in-out;
    background: rgba(31, 30, 30, 0.65);
    cursor: pointer;
    border-radius: 7px;
}

.team-detail:hover .outer-img-container {
    opacity: 0.8;
}

.team-name {
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 122.02%;
    letter-spacing: 0.005em;
    width: 70%;
}

.member-post {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 122.02%;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}


.img-pointer {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 26px;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
}

.team-detail:hover .img-pointer {
    opacity: 1;
}

/* Newsletter section */
.newsletter-section {
    padding: 0 5rem;
}

.newsletter-container {
    width: 50%;
}

.subscribe-container {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 42%;
}

.email-icon-ph {
    position: absolute;
    left: 125px;
    top: 11px;
    font-size: 21px;
    color: #9C9C9C;
}

.newsletter-p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: #4B4B4B;
    margin-bottom: 7px;
}

.subscribe-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    width: 80%;
}

.subscribe-p {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: #61a561;
    background: whitesmoke;
    padding: 10px;
}

#email {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    text-transform: capitalize;
    color: #9C9C9C;
    height: 40px;
    width: 100%;
    padding-left: 10px;
}

.subscribe-btn {
    cursor: pointer;
    background: #004684;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    border-color: #004684;
    padding: 3px 34px !important;
    height: 40px;
    transition: all 0.4s ease-in-out;
}

.subscribe-btn:hover {
    color: #004684;
    background: none;
}

/* Quality policy section */
.quality-policy-container {
    position: fixed;
    right: 0px;
    top: 179px;
    z-index: 9;
}

.badge-container {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 29px;
    right: 12px !important;
    text-align: center;
    z-index: 100;
}

.badge-container img {
    width: 100% !important;
}

.main-container .show-badge-detail {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease-in-out;
}

.main-container .hide-badge-detail {
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.quality-container {
    padding: 10px 15px;
    background: #004684;
    border-radius: 23px;
    position: fixed;
    width: 492px !important;
    height: 106px;
    bottom: 2px;
    right: 3px;
    z-index: 100;
}

.quality-heading {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 156.52%;
    /* or 39px */
    letter-spacing: 0.005em;
    color: #FFFFFF;
}

.quality-detail {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 156.52%;
    width: 85%;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}

/* Media query */
@media(max-width: 1024px) {

    /* Biotech overview section */
    .data {
        font-size: 60px;
    }

    .overview-container {
        padding: 2.5rem 3rem;
    }

    /* Partners section */
    .partners-container {
        padding: 2rem 3rem;
        height: auto;
    }

    .partners-detail {
        width: 80%;
    }

    .about-founder-img img {
        width: 86%;
        height: 82%;
    }

    .team-name {
        font-size: 25px;
        width: 100%;
    }

    .nm-team-container {
        padding: 1rem 3rem;
    }



    .team-heading-underline {
        width: 231px;
    }

    /* Newsletter sction */

    .newsletter-section {
        padding: 0 3rem;
    }

    .subscribe-heading {
        font-size: 30px;
    }

    /* Quality badege section */
    .badge-container {
        right: 0;
        bottom: 32px;
    }

    .badge-container img {
        width: 70%;
    }
}

@media(max-width: 769px) {

    /* Aboutus banner section */
    .about-heading {
        font-size: 40px;
    }

    /* Biotech overview section */
    .overview-detail {
        width: 100%;
    }

    .data {
        font-size: 41px;
    }

    .client-data {
        font-size: 16px;
    }

    .overview-detail {
        text-align: justify;
    }

    /* Partner section */

    .team-member-detail {
        transition: opacity 0.2s ease-in-out;
        background: rgba(31, 30, 30, 0.65);
        width: 100%;
        padding: 10px !important;
        bottom: 0;
        min-height: 80px;
    }

    .outer-img-container {
        transition: none;
        background: none;
    }

    .partners-container {
        padding: 2rem 3rem;
    }


    .img-pointer {
        opacity: 1;
    }

    .outer-img-container {
        opacity: 0.8;
    }

    .img-div img {
        width: 75%;
    }

    .leadership-heading {
        font-size: 40px;
    }

    .about-leadership-container {
        flex-direction: column;
    }

    .about-leadership-detail {
        width: 100%;
    }

    .about-founder-img {
        width: 100%;
    }

    .leadership-detail {
        text-align: justify;
    }

    .team-name {
        font-size: 16px;
        margin-bottom: 5px;
    }

    .member-post {
        font-size: 14px;
        margin-bottom: 0;
    }

    .team-member-detail {
        padding: 0 10px;
    }

    /* Newsletter section */
    .newsletter-outer-container {
        flex-direction: column;
    }

    .newsletter-container {
        width: 100%;
    }

    .subscribe-container {
        width: 100%;
        justify-content: left !important;
    }

    .subscribe-btn:hover {
        color: white;
        background-color: #004684;
    }

}

@media(max-width: 541px) {
    .badge-container {
        width: 51px;
        height: 51px;
    }

    .quality-container {
        width: 409px !important;
    }

    .quality-detail {
        font-size: 11px;
    }
}

@media(max-width: 426px) {

    /* Aboutus banner section */
    .about-heading {
        font-size: 35px;
    }

    .about-container {
        height: 200px;
    }

    .about-banner-inner-container {
        top: 80px;
    }

    /* Biotech overview section */
    .overview-container {
        padding: 32px 20px;
    }

    .overview-heading {
        font-size: 25px;
    }

    .overview-detail {
        font-size: 14px;
    }

    .data-container {
        margin: 8px 0;
        padding: 0;
    }

    /* Partners section */

    .partners-container {
        padding: 30px 20px;
    }

    .partners {
        font-size: 30px;
    }

    .partners-detail {
        width: 100%;
    }

    .img-div img {
        width: 55%;
        margin: 26px 0;
    }

    .img-div {
        text-align: center;
    }

    .aoutus-image-container {
        margin-top: 0px;
    }

    .leadership-sub-heading {
        font-size: 25px;
    }

    .leadership-heading {
        font-size: 40px;
    }

    /* NM team section */
    .nm-team-container {
        padding: 16px 20px 0 20px;
    }

    .nm-team-heading {
        font-size: 25px;
    }

    .team-heading-underline {
        width: 182px;
    }

    .team-img-container {
        flex-direction: column;
    }

    .team-detail {
        width: 90%;
        margin: 10px auto;
    }

    /* Newsletter section */
    .subscribe-heading {
        font-size: 25px;
    }

    .subscribe-heading {
        width: 100%;
    }

    .newsletter-container p {
        text-align: justify;
        font-size: 15px;
    }

    .newsletter-section {
        padding: 0 20px;
    }

    .badge-container {
        /* right: 5px; */
        bottom: 31px;
    }

    .quality-container {
        width: 99% !important;
        left: 3px;
    }

    .quality-heading {
        font-size: 18px;
    }

    .main-container {
        width: 420px;
    }

    #email {
        width: 70%;
    }

    .subscribe-btn {
        padding: 3px 13px !important;
    }

    .leadership-detail {
        font-size: 14px;
    }
}

@media(max-width: 376px) {

    /* Biotech overview section */
    .data {
        font-size: 35px;
    }

}

/* Quality policy section */
.main-container {
    width: 300px;
}

/* .quality-container {
    width: 303px;
    right: 1px;
} */

.badge-container img {
    width: 61%;
}

.badge-container {
    right: -9px;
}

.quality-heading {
    font-size: 15px;
}

.quality-detail {
    width: 85%;
}

/* Newsletter section */
.subscribe-btn {
    padding: 3px 6px;
}


@media(max-width: 320px) {
    .leadership-heading {
        font-size: 35px;
    }

    .badge-container {
        width: 42px;
        height: 44px;
    }
    .quality-detail {  
        line-height: 133.52%;
    }
}

@media(max-width: 281px) {
    /* .team-member-detail {
        top: 159px;
    } */

    .main-container {
        width: 278px;
    }

    .quality-detail {
        display: none;
    }

    .quality-container {
        width: 182px;
        height: 83px;
        display: flex;
        align-items: center;
    }

    .badge-container {
        bottom: 7px;
    }
}


.formContainer{
    width: 42%;
}
.subContainer{
    display: flex;
    justify-content: end;
 }

@media(max-width:768px){
    .formContainer{
        width: 100%;
    }

    .subContainer{
        justify-content: start;
    }    
}

