.address_form {
  position: absolute;
  width: 100%;
  background: #000;
  height: 100%;
}

.methods .heading {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.methods p.sub-content {
  color: #80858b;
  min-width: 350px;
  max-width: 350px;
}

.setBackground {
  background: #004684 !important;
  color: #fff !important;
}

.shipping-page {
  padding: 50px 100px;
  display: flex;
}
.shipping-page .first_section {
  width: 55%;
}
.shipping-page .first_section h4 {
  font-weight: 600;
  font-size: 30px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
}
.shipping-page .first_section .first_row_card {
  display: flex;
  justify-content: space-between;
}
.shipping-page .first_section .shipping-card {
  /* width: 48%; */
  min-height: 240px;
  border: 1px solid #d9d9d9;
  margin-bottom: 15px;
}
.shipping-page .first_section .shipping-card .plus-icon {
  color: #575656;
}
.shipping-page .first_section .shipping-card .new-address-icon {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 156.52%;
  /* or 23px */
  letter-spacing: 0.005em;
  color: #575656;
}
.shipping-page .first_section .card_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.shipping-page .first_section .card_2 {
  padding: 15px 15px 0 15px;
}
.shipping-page .first_section .card_2 button {
  border: none;
  /* background: #004684; */
  padding: 10px 33px;
  color: #fff;
}

.coupon_list > button {
  margin: 2px;
  display: flex;
}

.shipping-page .first_section .card_2 h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.005em;
  color: #061a00;
  margin-bottom: 0;
}
.shipping-page .first_section .card_2 span {
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.005em;
  color: #575656;
}
.shipping-page .first_section .card_2 p {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.005em;
}
.shipping-page .first_section .card_2 .edit_button {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.005em;
  margin: 0;
  text-align: right;
  color: #575656;
}
.shipping-page .first_section .card_3 {
  padding: 15px 15px 0 15px;
}
.shipping-page .first_section .card_3 button {
  border: none;
  background: white;
  border: 1px solid #004684;
  padding: 10px 33px;
  font-weight: 600;
  color: #004684;
  font-size: 15px;
}
.shipping-page .first_section .card_3 button:hover {
  background: #004684;
  color: #fff;
}
.shipping-page .first_section .card_3 h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.005em;
  color: #061a00;
  margin-bottom: 0;
}
.shipping-page .first_section .card_3 span {
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.005em;
  color: #575656;
}
.shipping-page .first_section .card_3 p.order_address {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
  min-height: 80px;
  margin-bottom: 0;
}
.shipping-page .first_section .card_3 .edit_button {
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.005em;
  margin: 0;
  text-align: right;
  color: #575656;
}
.shipping-page .second_section {
  width: 45%;
  padding-left: 80px;
}
.shipping-page .second_section h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.005em;
  color: #061a00;
  margin-bottom: 20px;
}
.shipping-page .second_section .quantity {
  display: flex;
  align-items: center;
  margin-left: -212px;
  margin-top: 78px;
}
.shipping-page .second_section .quantity button {
  border: none;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 4px 12px;
  color: #9c9c9c;
}
.shipping-page .second_section .quantity span {
  padding: 0 5px;
  color: #9c9c9c;
}
.shipping-page .second_section .quantity img {
  padding-left: 7px;
  height: 14px;
}
.shipping-page .second_section .div_section {
  margin-bottom: 50px;
}
.shipping-page .second_section .detail_section .background_div {
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 10px 0;
}
.shipping-page .second_section .detail_section .background_div .img_div {
  height: 116px;
  width: 120px;
  margin: auto;
}
.shipping-page .second_section .detail_section .background_div .img_div img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.shipping-page .second_section .detail_section .product_additional_detail h5 {
  margin-bottom: 0;
  font-size: 20px;
  color: #004684;
  min-width: 280px;
  max-width: 280px;
  overflow-wrap: anywhere;
}
.shipping-page .second_section .detail_section .product_additional_detail span {
  color: #50ae31;
  font-size: 10px;
  min-width: 220px;
  overflow-wrap: anywhere;
}
.shipping-page .second_section .detail_section .product_additional_detail p {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #004684;
  margin: 0;
}
.shipping-page
  .second_section
  .detail_section
  .product_additional_detail
  p:last-child {
  font-weight: 400;
  font-size: 14px;
}
.shipping-page .second_section .coupon-section {
  display: flex;
  padding-bottom: 35px;
  border-bottom: 1px solid #575656;
  margin-bottom: 20px;
}
.coupon_list button {
  background: #004684;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  transition: all 0.3s ease;
}
.coupon_list {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #575656;
  display: flex;
  align-items: center;
}
.coupon_list_p {
  margin-bottom: 10px;
  font-size: 15px;
  color: #575656;
  font-weight: 500;
}
.shipping-page .second_section .coupon-section .add_coupon {
  border: none;
  margin-left: 10px;
}
.shipping-page .second_section .coupon-section .add_coupon:focus-visible {
  outline: none;
}
.shipping-page .second_section .coupon-section button {
  border: none;
  width: 30%;
  height: 54px;
  left: 1236px;
  background: #004684;
  color: white;
}
.shipping-page .second_section .coupon-section .input_div {
  border: 1px solid #575656;
  width: 70%;
  height: 54px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.shipping-page .second_section .total_amount .ul_class {
  padding-left: 0.2rem;
  list-style: none;
}
.shipping-page .second_section .total_amount .ul_class li {
  font-size: 15px;
  line-height: 156.52%;
  letter-spacing: 0.005em;
  color: #575656;
  font-weight: 500;
}
.shipping-page .second_section .total_amount .ul_class:last-child {
  border-bottom: 1px solid #575656;
  border-top: 1px solid #575656;
  padding-bottom: 10px;
  padding-top: 10px;
}
.shipping-page .second_section .total_amount .ul_class:last-child li {
  font-weight: 700;
  color: #061a00;
}
.shipping-page .second_section .proceed_to_pay_button {
  background: #004684;
  text-align: center;
}
.shipping-page .second_section .proceed_to_pay_button button {
  border: none;
  background: transparent;
  height: 44px;
  color: white;
  font-size: 15px;
  font-weight: 600;
}
.shipping-page .second_section .proceed_to_pay_button button:focus {
  outline: none;
}
.shipping-page table tbody tr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shipping-page table td {
  border: none;
  padding: 8px;
}

@media (max-width: 1440px) {
  .shipping-page {
    padding: 50px;
  }
  .shipping-page .second_section .detail_section .product_additional_detail {
    width: 180px;
    margin-right: 59px;
    /* padding-left: 20px; */
  }
  .shipping-page .second_section .detail_section .background_div .img_div {
    height: 95px;
  }
  .shipping-page .second_section .coupon-section .input_div {
    padding-left: 10px;
  }
  .shipping-page .first_section {
    padding: 0;
  }
  .shipping-page .first_section .card_2 button {
    padding: 10px 20px;
  }
  .shipping-page .first_section .card_3 button {
    padding: 5px 10px;
  }
  .shipping-page .first_section .card_3 p.order_address {
    min-height: 60px;
  }
  .shipping-page .first_section .shipping-card {
    min-height: 205px;
  }
}
@media (max-width: 1024px) {
  .shipping-page .second_section {
    padding-left: 40px;
  }
}
@media (max-width: 768px) {
  .shipping-page {
    display: block;
  }
  .shipping-page .first_section {
    width: 100%;
  }
  .shipping-page .second_section {
    width: 100%;
    padding-left: 0;
  }
  
p.card_content {
  margin-left: 13px;
}
h3.sub_heading{
  margin-left: 13px;
}
.ordered_product_div.mt-2 {
  margin-left: 15px;
  margin-right: 15px;
}
.order-img-div {
  margin-left: 15px;
  margin-top: 16px;
}
  
}
.modal {
  display: block;
}

@media (max-width: 525px) {
  .shipping-page {
    padding: 20px 20px 0 20px;
  }
  .shipping-page .first_section .shipping-card {
    width: 100%;
  }
  .shipping-page .first_section .first_row_card {
    display: block;
  }

}

@media (max-width: 321px) {
  .methods p.sub-content {
    min-width: 0px;
  }
  .shipping-page .second_section .coupon-section .add_coupon {
   width: 158%;
}
.shipping-page .second_section .coupon-section .input_div {
 
  width: 110%;
}
.row {

  margin-left: -0.5px;
}
}
