.galleryContainer .outerContainer{
    padding: 50px 50px 0 50px;
    text-align: center;
}

.galleryContainer .mainHeading {
    font-family: var(--alice);
    font-weight: 00;
    font-size: 40px;
    line-height: 51px;
    margin: auto;
    color: var(--dark);
    position: relative;
    display: inline;
   line-height: 60px;
}

.galleryContainer .leaf {
    position: absolute;
    top: 4px;
    right: 27px;
}

.galleryContainer .image {
    height: 250px;
}

.galleryContainer .galleryContent {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 21px;
}

.galleryContainer .videoContainer {
    margin-top: 38px;
    position: relative;
    padding: 50px;
}

.galleryContainer .background {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #c4eec4;
}

.galleryContainer .subHeading {
    font-family: var(--alice);
    font-weight: 00;
    font-size: 40px;
    line-height: 51px;
    margin: auto;
    text-align: center;
    width: 35%;
    font-size: 37px !important;
    margin: 15px auto;
    color: #004684 !important;
}

@media(max-width:1024px) {
   .galleryContainer .outerContainer{
        padding: 30px 30px 0 30px;
    }

    .galleryContainer .videoContainer {
        padding: 30px;
    }

    .galleryContainer .mainHeading {
        font-size: 35px;
    }

   .galleryContainer  .subHeading {
        width: 80%;
        font-size: 30px !important;
    }

   .galleryContainer .image {
        height: 170px;
        border-radius: 15px;
    }

   .galleryContainer .image img, video {
        border-radius: 15px;
        height: 100%;
        width: 100%;
    }
   .galleryContainer .leaf {
        top: 0px;
        right: 23px;
    }
    
}

@media(max-width:540px) {
   .galleryContainer .outerContainer{
        padding: 20px 20px 0 20px;
    }

   .galleryContainer .videoContainer {
        padding: 20px;
    }

   .galleryContainer .mainHeading {
        font-size: 25px;
    }

   .galleryContainer .subHeading {
        width: 100%;
        font-size: 22px !important;
        line-height: 31px;
    }
   .galleryContainer .leaf {
        top: -3px;
        right: 14px;
    }
}