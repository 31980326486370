/* Footer section */

.nm_footer {
    padding-top: 4rem;
    background-color: white;
}
.nm_footer .footer {
    padding: 2rem 5rem;
    /* margin: 4rem 0 0 0; */
    background: url("/public/img/bg-pattan2.png");
    background-color: #004684;
    box-sizing: border-box;
    margin: 0;
}
.nm_footer_bar .footer {
    padding: 2rem 5rem;
    /* margin: 4rem 0 0 0; */
    background: url("/public/img/bg-pattan2.png");
    background-color: #004684;
    box-sizing: border-box;
    margin: 0;
}

.nm_footer .desktop_explore,
.nm_footer .desktop_contact,
.nm_footer .desktop_gallery {
    display: block;
}

.nm_footer .mobile_explore,
.nm_footer .mobile_contact,
.nm_footer .mobile_gallery {
    display: none !important;
}

.nm_footer .footer-logo-section {
    color: white;
    cursor: pointer;
}

.nm_footer .footer-address-hover .footer-address {
    font-size: 16px !important;
}

.nm_footer .footer-address-hover:hover {
    text-decoration: underline;
    opacity: 1;
}

.nm_footer .footer-logo {
    width: 80%;
    height: auto;
}

.nm_footer .footer-para {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 156.52%;
    margin: 16px 0;
    letter-spacing: 0.005em;
    opacity: 0.8;
    width: 85%;
}

.nm_footer .footer-nav-items {
    padding: 0;
    transition: opacity 0.3s ease-in-out;
}

.nm_footer .radient-icon {
    height: 3px;
    width: 30px;
    /* margin: 0px; */
    margin: 0 10px;
    background: linear-gradient(269.72deg, #ed3237 -32.45%, #599408 103.67%);
}

.nm_footer .footer-nav-items a {
    text-decoration: none;
}

.nm_footer .social-icons-container {
    font-size: 35px;
}

.nm_footer .social-icons-container .social-icons {
    padding: 8px;
    border-radius: 100%;
    margin-right: 10px;
    border: 1px solid white;
    color: white;
    opacity: 0.7;
}

.nm_footer .contact-conent a {
    color: white;
    opacity: 0.8;
    text-decoration: none;
}

.nm_footer .social-icons:hover {
    opacity: 1;
}

.nm_footer .contact-conent {
    padding-top: 20px;
    opacity: 0.8;
}

.nm_footer .footer-nav-items .nav-item {
    color: white;
    list-style: none;
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 141.4%;
    padding-top: 20px;
    padding-left: 0 !important;
    opacity: 0.8;
    text-decoration: none !important;
}

.nm_footer .footer-nav-items .nav-item:hover {
    text-decoration: underline;
    opacity: 1;
}

.nm_footer .footer-heading {
    font-size: 20px;
    line-height: 24px;
    font-weight: bolder;
    color: white;
}

.nm_footer .footer-sub-heading {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    margin: 0;
}

.nm_footer .footer-contact-icons {
    font-size: 1.3rem;
    margin-right: 16px;
}

.nm_footer .footer-location-icons {
    font-size: 1.3rem;
    margin-right: 12px;
}

.nm_footer .footer-address {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    margin: 0;
}

.nm_footer .footer-gallery-container {
    padding: 20px 0;
    width: 100%;
}
.nm_footer .footer-gallery-container .row {
    margin: 0;
}
.nm_footer .gallery-img {
    padding: 10px 9px 10px 0px;
    width: 33.333333%;
}

.nm_footer .view-all{
    color: #FFF !important;
    opacity: 0.8;
}

.nm_footer .view-all:hover{
    opacity: 1;
    color: white !important;
    text-decoration: underline;
}

.nm_footer_bar .footer-bar {
    padding: 2rem 5rem;
    background: #022e55;
    color: white;
}

.nm_footer_bar .footer-bar-content {
    /* font-family: "Inter"; */
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 141.4%;
    margin: 0;
    opacity: 0.8;
}

.nm_footer_bar .footer-bar-content:hover {
    text-decoration: underline;
    opacity: 0.6;
}

.nm_footer .footer-contact-inner-container {
    width: 75% !important;
}

.nm_footer .gallery {
    width: 80%;
}

.nm_footer_bar .footer-bar a {
    text-decoration: none;
    color: white;
}

@media (max-width: 1024px) {
    .nm_footer .footer-address {
        margin: 0 10px;
        text-align: left;
    }

    .nm_footer .footer-sub-heading {
        width: auto;
        margin: 0 10px;
        text-align: left;
    }

    /* .nm_footer .footer-location-icons {
        font-size: 2.4rem;
    } */

    .nm_footer .footer {
        padding: 2rem 3rem;
    }

    .nm_footer_bar .footer-bar {
        padding: 2rem 3rem;
    }
}

@media (max-width: 991px) {

    /* Footer section */
    .nm_footer .footer-location-icons,
    .nm_footer .footer-contact-icons {
        margin: 0;
    }

    .nm_footer .footer-contact-container {
        justify-content: start;
    }
}

@media (max-width: 768px) {

    .nm_footer .desktop_explore,
    .nm_footer .desktop_contact,
    .nm_footer .desktop_gallery {
        display: none !important;
    }

    .nm_footer .mobile_explore,
    .nm_footer .mobile_contact,
    .nm_footer .mobile_gallery {
        display: block !important;
    }

    .nm_footer .footer-nav-items-mobile,
    .nm_footer .gallery_mobile,
    .nm_footer .contact_navbar_mobile {
        display: none;
    }

    .nm_footer .mobile_explore .footer-heading,
    .nm_footer .mobile_contact .footer-heading,
    .nm_footer .mobile_gallery .footer-heading {
        justify-content: space-between;
        background-color: #50ae31;
        padding: 15px;
    }

    .nm_footer .footer-contact-inner-container {
        width: 100% !important;
    }

    .nm_footer .footer-section {
        margin: 12px 0;
    }

    .nm_footer .footer-logo {
        width: 55%;
    }

    .nm_footer_bar .footer-bar {
        align-items: center;
    }
}

@media (max-width: 426px) {

    /* Footer Section */
    .nm_footer .footer {
        padding: 1rem 20px;
    }

    .nm_footer .footer-para {
        margin-bottom: 0;
        margin-top: 13px;
        width: 100%;
    }

    .nm_footer .gallery {
        width: 100%;
    }

    .nm_footer_bar .footer-bar {
        padding: 1rem 2rem;
    }

    .nm_footer_bar .footer-bar-content {
        font-size: 14px;
    }
}

@media (max-width: 375px) {
    .nm_footer .footer-heading {
        font-size: 18px;
    }
}

/*# sourceMappingURL=footer.css.map */