.leadership-page {
    padding: 32px 100px 0 100px;
}

.founder-container {
    display: flex;
}

.founder-container .about-founder {
    width: 60%;
}

.founder-container .founder-img-div {
    width: 40%;
    height: 414px;
    padding-left: 53px;

}

.leader_image {
    width: 100%;
    height: 100%;
}

.founder-img-div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.about-founder .founder-name {
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: #004684;
}

.about-founder .founder-title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 122.02%;
    /* or 24px */

    letter-spacing: 0.005em;

    color: #50AE31;
}

.founder-deail {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: #575656;
}

.team-member-container {
    display: flex;
}

.members-detail {
    background: #004684;
    border-radius: 9px;
}

.members-detail .member-name {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.members-detail .member-post {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 122.02%;
    letter-spacing: 0.005em;
    color: #FFFFFF;
}

.team-member {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.border-green {
    border-right: 1px solid #50AE31;

}

.border-circle {
    position: absolute;
    width: 13px;
    height: 13px;
    border: 1px solid #50AE31;
    border-radius: 100%;
}

.top-circle {
    right: -7px;
    top: -13px;

}

.bottom-circle {
    right: -7px;
    bottom: -13px;
}


.about-team-member {
    width: 50%;
}

.members-detail {
    text-align: center;
    padding: 21px;
    margin-top: 19px;
}

.about-team-member-p {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: #575656;
    margin-top: 50px;
}

.about-team-member .detail-right {
    padding-right: 58px;
}

.about-team-member .detail-left {
    padding-left: 58px;
}

.team-img {
    position: relative;
    width: 164px;
    height: 164px;
    /* border: 2px solid #50AE31;
    border-radius: 100%; */
}

.team-img .team-member-img {
    width: 100%;
    height: 100%;
}

.img-shadow{
    position: absolute;
    right: 0;
    bottom: 0;
}

.img-border {
    position: absolute;
    left: 6px;
    bottom: 8px;
    width: 164px;
    height: 164px;
    border: 1px solid #50AE31;
    border-radius: 100%;
}

.border-dot {
    position: absolute;
    right: 64px;
    top: -15px;
    width: 13px;
    height: 13px;
    background-color: #50AE31;
    border-radius: 100%;
}

.team-icons-left {
    position: absolute;
    position: absolute;
    bottom: 113px;
    left: -26px;
}

.team-icons-right {
    position: absolute;
    position: absolute;
    bottom: 113px;
    right: -26px;
}

/* Mwedia Query */
@media(max-width:1024px) {
    .leadership-page {
        padding: 32px 48px 0px 48px;
    }

    .about-founder .founder-name {
        font-size: 40px;
    }
}

@media(max-width: 769px) {
    .border-green {
        border-right: none;
    }

    .border-circle {
        display: none;
    }

    .team-icons-left,
    .team-icons-right {
        display: none;
    }

    .team-member-container {
        flex-direction: column;
        padding-top: 20px;
    }

    .about-team-member,
    .team-member {
        width: 100%;
    }

    .team-member-second {
        flex-direction: column-reverse !important;
    }

    .detail-right {
        padding-right: 0px !important;
    }

    .detail-left {
        padding-left: 0px !important;
    }

    .about-team-member-p {
        margin-top: 30px;
    }

    .founder-container {
        flex-direction: column-reverse;
    }

    .founder-container .about-founder {
        width: 100%;
        padding-top: 20px;
    }

    .founder-container .founder-img-div {
        width: 100%;
        padding-left: 0px;
    }

    .founder-name {
        font-size: 32px;
    }
}

@media(max-width:426px) {
    .leadership-page {
        padding: 20px 20px 0 20px;
    }

    .founder-container .founder-img-div {
        padding-left: 0px;
    }

    .about-founder .founder-name {
        font-size: 30px;
    }

    .founder-container .founder-img-div {
        height: auto;
    }

    .about-team-member-p,
    .founder-deail {
        text-align: justify;
    }
}

@media(max-width:321px) {
    .about-founder .founder-name {
        font-size: 25px;
    }

    .members-detail {
        padding: 15px;
        margin-top: 15px;
    }

    .members-detail .member-name {
        font-size: 18px;
    }
    .members-detail .member-post {
        font-size: 13px;
    }
    .founder-deail{
        line-height: 150.52%;
    }
}