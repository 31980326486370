.invalidDataError {
    color: red !important;
}
  
  .toggleSwitch {
    width: 50px;
    height: 25px;
    border-radius: 15px;
    background-color: #e0e0e0;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* left: 95.5%;
    margin: 10px 0px; */
  }
  
  .toggleSwitch:hover {
    background-color: #cfcfcf;
  }
  
  .knob {
    width: 21px;
    height: 21px;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .knob.active {
    left: 27px;
    background-color: #004583;
  }

  .toggleContainer{
    display: flex;
    justify-content: end;
    gap: 10px;
    margin: 10px 0px;
  }

  .dialogModel{
    width: 75% !important;
    height: 70% !important;
    max-height: 70%;
  }
  

  .previewTable{
    max-height: 120px;
    overflow-y: scroll;
    border: 1px solid black;
  }
  