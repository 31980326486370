.container-fluid {
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.page-header {
  border: none;
}
.admin-footer {
  bottom: 0px !important;
  position: relative !important;
  width: 99% !important;
  background-color: #f1f1f1 !important;
  padding: 1.25rem 0;
  font-size: 14px;
}

.page .section-body:nth-child(2) {
  background-color: #f1f1f1 !important;
  height: 100% !important;
  margin-top: 0px !important;
}
.page .section-body:nth-child(3) {
  background-color: #f1f1f1 !important;
  height: 100% !important;
  margin-top: 0px !important;
}
/* .section-body .card {
	min-height: 62vh;
}  */
#page {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  left: 4%;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: calc(100% - 4%);
  position: relative;
}
@media (max-width:767px) {
  #page {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    left: 0%;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: calc(100% - 0%);
    position: relative;
  }
}
@media (max-width:767px) {
  #responsiveDrop{
    display: flex;
    flex-direction: row-reverse;
  }
}

.spinner_main_div {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  #header_top {
    /* border-bottom: 1px solid rgba(0, 40, 100, 0.12); */
    background: rgb(0, 69, 131);
  }
}
@media screen and (min-width: 768px) {
  #userIcon {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  #header_top {
    /* border-bottom: 1px solid rgba(0, 40, 100, 0.12); */
    background: rgb(0, 69, 131);
    flex-direction: row;
    height: auto;
    width: 100%;
  }
  #header_top .hleft,
  #header_top .dropdown {
    display: flex;
    align-items: center;
  }
  #header_top .header-brand {
    margin: 0;
    padding-right: 15px;
  }
}
@media screen and (max-width: 576px) {
  .page-header {
    display: none;
  }
  .header_top_sm {
    background: rgb(0, 69, 131);
  }
  #ProfileUser {
    margin-top: -13px;
  }
}
