.dashboard_order_container {
    padding: 0px 24px 24px 24px;
}

.dashboard_order_container .box_shadow {
    box-shadow: 2px 2px 11px 3px rgba(0, 0, 0, 0.10);
}

.dashboard_order_container .outer-container {
    display: grid;
    grid-template-columns: 30% 67%;
    grid-gap: 20px;
    padding: 15px;
}
.dashboard_order_container .order-outer-container{
    display: grid;
    grid-template-columns: 65% 35%;
    grid-gap: 20px;
    padding: 15px;
}

.dashboard_order_container .ordered_product_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product_delivery_detail {
    display: flex;
    justify-content: space-between;
}

.dashboard_order_container .ordered_product_div {
    box-shadow: none;
    padding: 0px !important;
    align-items: center;
    justify-content: left !important;
}

.dashboard_order_container .order_heading {
    color: #061A00;
    font-size: 20px;
    font-weight: 600;
    padding: 20px;
}

.dashboard_order_container .order_sub_heading {
    color: #061A00;
    font-size: 20px;
    font-weight: 600;
    padding: 20px 0;
}

.dashboard_order_container .deliver_div {
    text-align: end;
}

.dashboard_order_container .deliver_btn {
    background: #004684;
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    padding: 9px 0px;
    margin: 15px 0;
    display: block;
    width: 100%;
    text-align: center;
    border: none;
}

.delivered_date {
    margin: 16px 0;
    color: #757678;
    font-size: 20px;
}

.dispatch_div {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.order_status_name {
    color: #757678;
    font-size: 20px;
    line-height: 156.523%;
    letter-spacing: 0.09px;
    margin: 0;
}

.status_dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #50ae31;
    margin: 0 10px;
}
.cancelled_status_dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: red;
    margin: 0 10px;
}
.pending_status_dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color:#e0bf01;
    margin: 0 10px;
}

.order_address {
    color: #757678;
    font-size: 16px;
}

.dashboard_order_container .text-danger {
    background: red !important;
}

.dashboard_order_container .cancelled_div {
    padding: 15px;
}

.order-img-div img {

    width: 100% !important;
    height: auto;
}

.disabled {
    background-color: grey;
    color: #ccc; /* You can adjust the text color as well */
    cursor: not-allowed;
  }

/* Media query */
@media(max-width: 1025px) {
    .dashboard_order_container .outer-container {
        grid-template-columns: 35% 62%;
    }

    .dashboard_order_container .address {
        width: 60%;
        margin-left: 0;
    }

    .dashboard_order_container .deliver_div {
        width: 38% !important;
    }

    .order_status_name,
    .delivered_date,
    .dashboard_order_container .order_sub_heading {
        font-size: 16px;
    }

    .ordered_product_div .order-img-div {
        height: 98px !important;
    }

    .dashboard_order_container .deliver_div {
        width: 24%;
        text-align: start;
    }

}

@media (max-width: 769px) {
    .dashboard_order_container .outer-container {
        grid-template-columns: 100%;
    }

    .dashboard_order_container .deliver_div {
        width: 30% !important;
    }

    .dashboard_order_container .deliver_btn {
        font-size: 13px;
        padding: 7px 0px;
        margin: 13px 0;
    }

}

@media(max-width:426px) {
    .dashboard_order_container .outer-container {
        flex-direction: column;
    }
    .dashboard_order_container .order-outer-container{
       display: flex;
       flex-direction: column;
    }


    .dashboard_order_container .ordered_product_container {
        width: 100%;
    }

    .dashboard_order_container .deliver_div {
        width: 38% !important;
    }
}

@media (max-width: 376px) {

    .order_status_name,
    .delivered_date,
    .dashboard_order_container .order_sub_heading {
        font-size: 14px;
    }

    .order_address {
        font-size: 14px;
    }
}