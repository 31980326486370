
.header {
  text-align: center;
  margin-bottom: 20px;
}


.rows{
  display: flex;
  border-bottom: 1px solid black;
}


.column1,.column2{
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-start;
}

.boldText {
  font-weight: bold;
}

.goodsTable {
  width: 100%;
  margin-bottom: 20px;
  border: none;
  border-bottom: 1px solid black;
}
.goodsTable th,
.goodsTable td {
  padding: 8px;
  text-align: left;
  border: none;
}

.goodsTable th {
  font-weight: bold;
  border-bottom: 1px solid black;
}

.qrCodePlaceholder {
  border: 1px solid black;
  width: 100px;
  height: 100px;
}

.footer {
  text-align: left;
  margin-top: 20px;
}

@media print {
    /* Show the dialog and its contents */
    .p-dialog-content, /* PrimeReact Dialog content */
    .p-dialog-footer {
      /* Footer for buttons */
      display: block;
    }
    button,
    .noprint {
      display: none !important;
      opacity: 0;
    }
    .p-dialog-content {
      /* height: 1700px !important; */
      margin-top: -450px;
    }
  }
  
  .right-sec {
    padding-left: 40px;
  }
  
  .header-in {
    display: flex;
    /* justify-content: space-around; */
  }
  
  .header-in img {
    width: 100%;
  }
  
  .header-invoice th,
  td {
    border-right: 1px solid #000;
    padding-left: 5px;
  }
  
  /* .header-invoice th:last-child , td:last-child{
      border-right: none;
  } */
  
  .mid-line {
    padding: 5px 0;
    border-bottom: 1px solid #000;
    display: flex;
    justify-content: space-between;
  }
  
  .tax-heading {
    font-weight: bold;
  }
  
  .invoice-address{
    font-size: 11px;
  }
  
  .invoice-container{
    font-size: 11px;
  }
  
  .company_name{
    font-weight: bold;
  }
  
  .jumBotron{
    /* padding: 2rem; */
    padding: 0rem 2rem;
      /* margin-bottom: 2rem; */
      /* background-color: #e9ecef; */
      border-radius: .3rem;
  }
  
  @media(max-width: 576px){
    .jumBotron{
      padding: 2rem;
    }
  }
  
  .invoice-bill {
    display: flex;
    justify-content: space-between;
    border: 1px solid #000;
  }
  
  .invoice-date {
    border-right: 1px solid #000;
    padding-right: 10px;
    padding-left: 5px;
  }
  
  .invoice-bill-content {
    display: flex;
  }
  
  .invoice-bill-qrcode {
    /* width: 40%; */
    text-align: right;
  }
  
  .invoice-bill-qrcode img {
    width: 30%;
  }
  
  table {
    width: 100%;
    border: 1px solid #000;
  }
  
  .invoice-person-detail {
    padding-left: 10px;
  }
  
  .right-border {
    border-right: 1px solid #000;
    padding-right: 5px;
  }
  
  .left-border {
    border-left: 1px solid #000;
    padding-left: 5px;
  }
  
  .main-table tr {
    border-top: 1px solid #000;
  }
  
  .td_discount_igst {
    text-align: right;
    padding-right: 25px;
  }
  
  .mid-line-table th.first-th {
    width: 3.99%;
  }
  
  .main-table .s_no {
    width: 3.99%;
  }
  
  .product_name_td {
    width: 39.1%;
  }
  
  .header-invoice .date {
    width: 16.89%;
  }
  
  .amount-chargeble .amount-words .amount,
  .amount-chargeble .amount-words .eof {
    width: 50%;
  }
  
  .amount-chargeble .amount-words {
    display: flex;
  }
  
  .amount-eng {
    font-size: 18px;
  }
  
  .hsn_td2 {
    width: 60.6%;
  }
  
  .tax-amount tr {
    border-bottom: 1px solid #000;
  }
  .amount-integrate {
    width: 10%;
  }
  
  .tax-invoice {
    border: 1px solid #000;
    margin-top: 10px;
  }
  
  .company_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
  }
  
  .qr-code {
    display: flex;
    justify-content: space-between;
  }
  
  .bank_details {
    height: 15% !important;
  }
  

  .invoiceSectionBorderBottom{
    display: flex;
  border-bottom: 1px solid black;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px;
  }

  .pageBreak {
    page-break-before: always; /* Forces a page break before this element */
  }