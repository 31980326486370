.fc-event-container .fc-event,
.fc-event-container .fc-event-dot {
	background-color: #39374e !important;
	color: #babdbf !important;
	border-color: #39374e !important;
}

.nav-link-gdc-selected {
	font-weight: bold !important;
}

div.font-montserrat {
	font-family: 'Montserrat', sans-serif;
}
div.font-roboto {
	font-family: 'Robot', sans-serif;
}
div.font-opensans {
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
}
/* div.rtl{ */
div.rtl {
	direction: rtl !important;
	text-align: right;
}
.sticky-top {
	width: calc(100% - 280px);
	position: fixed;
}
.marginTop {
	margin-top: 7.2% !important;
}

.breadcrumb-area {
	border-radius: 5px;
	display: flex;
	align-items: center;
	/* margin: 20px 0; */
	margin: 0;
}

.header_top {
	background-color: rgb(0, 69, 131);
}

.form-line {
	margin-top: 20px;
	border-top: 2px solid rgb(196, 194, 194);
}

.bordered-row {
	border: 1px solid lightgray;
	border-radius: 5px;
	padding: 10px;
	margin: 0px 0px 30px 0px;
}

.header_top {
	background-color: rgb(0, 69, 131);
}

.grater-than-route {
	font-size: 14px;
	color: black;
}

.route-heading {
	font-size: 14px;
}

.top-left-heading {
	font-weight: 700;
	font-size: 18px;
	color: black;
}

.active-page {
	background-color: #007bff;
	color: #fff;
}

.pagination-container {
	display: flex;
	flex-direction: column;
	align-items: center; /* Center the content horizontally */
	justify-content: center; /* Center the content vertically */
}

.pagination-message {
	text-align: center; /* Center the text */
	font-size: 13px;
}

@media(max-width:320px) {
.top-left-heading{
	font-size: 16px;
}
}
